import SettingsService from '../services/SettingsService';
import axios from 'axios';
import { getToken } from '../Upshow';
import UpshowLogger from '../Logger';
import ScriptService from '../services/ScriptService';
import MediaService from '../services/MediaService';
import SpotlightService from '../services/SpotlightService';
import DisplayRulesService from '../services/DisplayRulesService';
import * as UP from '../Upshow';
import ScreenService from '../services/ScreenService';
import StateService from '../services/StateService';

const media_states = ['upshownow', 'jukin', 'plutotv', 'trivia'];

export const nullOrTrue = (param) => param || param === null;

export const getSocialBrandKitStyle = () => {
    const { social_accent_color, social_background, logo } = SettingsService.getBrandKit();

    return {
        logo,
        backgroundColor: social_background,
        color: social_accent_color
    };
};

/**
 * Get authentication cookies from service
 * @param {string} url
 */
export const setAuthCookies = (url) => {
    return axios.get(`${url}`, {
        headers: {
            'x-access-token': getToken()
        },
        withCredentials: true
    })
        .catch(error => {
            UpshowLogger.error('Unable to sign URL', error, { url });
            throw Error;
        });
};

/**
 * Given a URL, this function adds parameters for identifying device and org if the URL seems to belong to UPshow
 * @param {string} url
 * @return {string}
 */
export function addDeviceOrgToURL (url) {
    if (!url.match(/\.upshow\.tv\//)) return url;

    let parsedUrl = new URL(url);

    parsedUrl.searchParams.set('device_id', SettingsService.getSetting('device_id'));
    parsedUrl.searchParams.set('org_id', SettingsService.getSetting('organization_id'));

    return parsedUrl.toString();
}

/**
 * Given a sequence, this function detects if there is no content available
 * @param {object[]} allSpotlights
 * @param {object[]} upshownow
 * @param {object[]} plutotv
 * @param {object[]} media_channel
 * @param {object[]} applicationInstances
 * @param {object[]} trivia
 * @param {boolean} hasWatchToWinSettings
 * @return {boolean}
 */
export const checkScriptContent = (allSpotlights, upshownow, plutotv, media_channel, applicationInstances, trivia, hasWatchToWinSettings) => {
    const sequence = ScriptService.getScript();

    const availableAppInstancesIds = applicationInstances.map(s => s.id);
    const availableMediaChannelIds = media_channel.map(s => s.id);
    const availablePlutoTvIds = plutotv.map(s => s.id);
    const availableUpshowNowIds = upshownow.map(s => s.id);
    const availableSpotlightsIds = allSpotlights.filter(s => !s.TrackingCode && !s.properties.includes('boh_spotlight')).map(s => s.id);
    const availableQRCodeIds = allSpotlights.filter(s => !!s.TrackingCode && !s.properties.includes('boh_spotlight')).map(s => s.id);

    if (ScreenService.isVertical) {
        return checkVerticalContent(sequence, availableSpotlightsIds, availableQRCodeIds);
    }

    for (const item of sequence) {
        if (item.state === 'spotlight') {
            if (checkSpotlight(item, availableSpotlightsIds, availableQRCodeIds)) return true;
        } else if (item.state === 'upshownow' && availableUpshowNowIds.length > 0) {
            if (foundValidContent(availableUpshowNowIds, item)) return true;
        } else if (item.state === 'plutotv' && availablePlutoTvIds.length > 0) {
            if (foundValidContent(availablePlutoTvIds, item)) return true;
        } else if (item.state === 'jukin' && media_channel.length > 0) {
            if (foundValidContent(availableMediaChannelIds, item)) return true;
        } else if (item.state === 'socialgrid' || item.state === 'socialzoom') {
            if (SettingsService.hasFeature('social') && SettingsService.hasTrueSetting('direct_share')) return true;
        } else if ((item.state === 'application' || item.state === 'app-application') && availableAppInstancesIds.length > 0) {
            if (foundValidContent(availableAppInstancesIds, item)) return true;
        } else if (item.state === 'trivia') {
            if (SettingsService.hasFeature('dr_time_based')) {
                const validMedia = MediaService.getValidMedia('trivia');
                if (validMedia.length > 0) return true;
            }

            const activeScheduleTrivia = MediaService.getAllActive(['trivia']);
            if (trivia.length > 0 && activeScheduleTrivia.length > 0) return true;
        } else if (item.state === 'watchtowin') {
            if (hasWatchToWinSettings) return true;
        } else if (item.state === 'vistar') {
            return true;
        } else if (item.state === 'iframe') {
            return true;
        }
    }

    return false;
};

const foundValidContent = (availableIds, { metadata, state, type }) => {
    // Display rules
    if (SettingsService.hasFeature('dr_time_based')) {
        let availableContent;
        if (state === 'spotlight') {
            let currentIsoDate = UP.getCurrentISODate();
            const ruleListValidator = DisplayRulesService.getRulesValidator(currentIsoDate);
            availableContent = SpotlightService.spotlights.filter(spotlight => ruleListValidator(spotlight.time_based_display_rules) && availableIds.includes(spotlight.id));
        }
        if (media_states.includes(state)) {
            availableContent = MediaService.getValidMedia(state);
        }
        if (state === 'application' && type === 'sportsbook') {
            availableContent = MediaService.getValidMedia('sportsbook');
        } else if (state === 'application' || state === 'app-application') {
            availableContent = MediaService.getValidMedia(type === 'plugin' ? 'plugin' : 'application');
        }

        if (availableContent) {
            //Granular
            if (Array.isArray(metadata?.id) && metadata.id.length > 0) {
                const availableIds = availableContent.map(s => s.id);
                return metadata.id.some((id) => availableIds.includes(id));
            }

            //No granular
            return availableContent.filter(content => !content.is_only_playlist).length > 0;
        }

        return true;
    }

    // Granular
    if (Array.isArray(metadata?.id) && metadata.id.length > 0) return metadata.id.some((id) => availableIds.includes(id));

    // Scheduled content
    if (state === 'spotlight') {
        //get current datetime to filter spotlights by schedule
        const currentIsoDate = UP.getCurrentISODate();
        const requiredProperties = ScreenService.isVertical ? ['clockwise_rotated'] : ['fullscreen'];
        const activeFullscreenSpotlights = SpotlightService.filterSchedules(SpotlightService.schedules, currentIsoDate, requiredProperties, false);
        const availableQRInSchedule = activeFullscreenSpotlights.filter(s => !!s.TrackingCode && !s.properties.includes('boh_spotlight'));
        const availableSpotlightsInSchedule = activeFullscreenSpotlights.filter(s => !s.TrackingCode && !s.properties.includes('boh_spotlight'));

        if (type === 'qr_code') {
            return availableQRInSchedule.length > 0;
        } else if (type === 'announcement') {
            return availableSpotlightsInSchedule.length > 0;
        } else if (type === 'boh_spotlight') {
            return false;
        } else {
            return availableSpotlightsInSchedule.length > 0 || availableQRInSchedule.length > 0;
        }
    }

    if (media_states.includes(state)) {
        const activeScheduleMedia = MediaService.getAllActive([state]);
        return activeScheduleMedia.length > 0;
    }

    if (state === 'application' || state === 'app-application') {
        const activeScheduleMediaApplication = MediaService.getAllActive(['application']);
        return activeScheduleMediaApplication.length > 0;
    }

    return true;
};

const checkVerticalContent = (sequence, availableSpotlightsIds, availableQRCodeIds) => {
    for (const item of sequence) {
        if (item.state === 'spotlight') {
            if (checkSpotlight(item, availableSpotlightsIds, availableQRCodeIds)) return true;
        } else if (item.state === 'socialgrid' || item.state === 'socialzoom') {
            if (SettingsService.hasFeature('social') && SettingsService.hasTrueSetting('direct_share')) return true;
        }
    }
    return false;
};

const checkSpotlight = (item, availableSpotlightsIds, availableQRCodeIds) => {
    if (item.type === 'qr_code' && availableQRCodeIds.length > 0) {
        if (foundValidContent(availableQRCodeIds, item)) return true;
    } else if (item.type === 'announcement' && availableSpotlightsIds.length > 0) {
        if (foundValidContent(availableSpotlightsIds, item)) return true;
    } else if (item.type === 'boh_spotlight') {
        return false;
    } else if (!item.type && availableSpotlightsIds.length > 0) {
        if (foundValidContent(availableSpotlightsIds, item)) return true;
    } else if (!item.type && availableQRCodeIds.length > 0) {
        if (foundValidContent(availableQRCodeIds, item)) return true;
    }
};

/**
 * Returns states that are available to show
 * @return {object}
 */
export function getAvailableStates () {
    const features = Object.keys(SettingsService.settings.features);
    const categoryStates = {
        social: [],
        entertainment: [],
        qr_campaigns: [],
        spotlights: [],
        plugins: ['application'],
    };
    if (features.includes('social')) {
        categoryStates.social = ['socialgrid', 'socialzoom'];
    }
    if (features.includes('campaigns')) {
        categoryStates.qr_campaigns = ['qr_campaign'];
    }
    if (features.includes('spotlights')) {
        categoryStates.spotlights = ['spotlight'];
    }

    if (features.includes('jukin') || features.includes('media_channel_non_premium') || features.includes('media_channel_nhl')) {
        categoryStates.entertainment.push('jukin');
    }
    if (features.includes('upshow_now')) {
        categoryStates.entertainment.push('upshownow');
    }
    if (features.includes('plutotv')) {
        categoryStates.entertainment.push('plutotv');
    }
    if (features.includes('upshow_games') && StateService.hasWatchToWinSettings) {
        categoryStates.entertainment.push('watchtowin');
    }
    if (features.includes('upshow_games') && SettingsService.hasUiSetting('game_id') && !ScreenService.isVertical) {
        categoryStates.entertainment.push('trivia');
    }
    if (features.includes('sportsbook')) {
        const activeSportsbook = MediaService.media.filter(i => i.application && i.application.feedType === 'SPORTSBOOK');
        if (activeSportsbook.length > 0) {
            categoryStates.entertainment.push('sportsbook');
        }
    }

    return categoryStates;
};