import UpshowLogger from '../Logger';
import * as UP from '../Upshow';
import _ from 'lodash';
import ScreenService from './ScreenService';
import { format_spotlight, format_spotlight_schedule } from '../libs/format_spotlight';
import { scheduleisActive } from '@upshow/sched-utils';
import { randomStart } from '../libs/randomize_start';
import SettingsService from './SettingsService';
import DisplayRulesService from './DisplayRulesService';
import ScriptService from './ScriptService';

const SpotlightService = {
    schedules: [],
    spotlights: [],
    invalidOrientationSpotlights: [],
    currentFullscreenSpotlight: null,
    spotlightIndex: {
        qrCode: -1,
        announcement: -1,
        default: -1
    },
    loadSpotlights: function loadSpotlights (spotlight_schedules, spotlights) {
        SpotlightService.schedules = spotlight_schedules.map(format_spotlight_schedule);
        SpotlightService.spotlights = spotlights.map(format_spotlight);
    },
    getSpotlightsScheduled: function getSpotlightScheduled () {
        return SpotlightService.schedules;
    },
    getSpotlight: (spotlightId) => {
        let spotlight = SpotlightService.spotlights.find(spot => spot.spt_id === spotlightId);

        if (!spotlight) {
            spotlight = SpotlightService.getSpotlightData(spotlightId);
            SpotlightService.spotlights.push(spotlight);
        }

        if (spotlight?.properties?.includes(ScreenService.isVertical ? 'clockwise_rotated' : 'fullscreen')) {
            return spotlight;
        } else {
            SpotlightService.invalidOrientationSpotlights.push(spotlightId);
        }
    },
    getNextFullscreenSpotlight: function getNextFullscreenSpotlight (type) {
        let activeSpotlights = SpotlightService.getActiveFullScreenSpotlights();

        let index;
        switch (type) {
            case 'boh_spotlight':
                index = -1
                break;
            case 'qr_code':
                activeSpotlights = activeSpotlights.filter(spotlight => (!!spotlight.TrackingCode));
                index = activeSpotlights.length ? (SpotlightService.spotlightIndex.qrCode + 1) % activeSpotlights.length : -1;
                SpotlightService.spotlightIndex.qrCode = index;
                break;
            case 'announcement':
                activeSpotlights = activeSpotlights.filter(spotlight => !spotlight.TrackingCode && !spotlight.properties.includes('boh_spotlight'));
                index = activeSpotlights.length ? (SpotlightService.spotlightIndex.announcement + 1) % activeSpotlights.length : -1;
                SpotlightService.spotlightIndex.announcement = index;
                break;
            default:
                activeSpotlights = activeSpotlights.filter(spotlight => (!spotlight.properties.includes('boh_spotlight')));
                index = activeSpotlights.length ? (SpotlightService.spotlightIndex.default + 1) % activeSpotlights.length : -1;
                SpotlightService.spotlightIndex.default = index;
        }

        SpotlightService.currentFullscreenSpotlight = activeSpotlights[index];

        if (SpotlightService.currentFullscreenSpotlight) {
            return Object.assign({}, SpotlightService.currentFullscreenSpotlight);
        }
    },
    getActiveFullScreenSpotlights: function getActiveFullScreenSpotlights (takeover) {
        if (SettingsService.hasFeature('dr_time_based') && !takeover) {
            let currentIsoDate = UP.getCurrentISODate();
            const timeBasedDisplayRuleValidator = DisplayRulesService.getRulesValidator(currentIsoDate);
            return SpotlightService.spotlights
                .filter(spotlight => timeBasedDisplayRuleValidator(spotlight.time_based_display_rules) && !spotlight.is_only_playlist);
        }
        //get current datetime to filter spotlights by schedule
        let currentIsoDate = UP.getCurrentISODate();
        let requiredProperties = ScreenService.isVertical ? ['clockwise_rotated'] : ['fullscreen'];
        let activeFullscreenSpotlights = SpotlightService.filterSchedules(SpotlightService.schedules, currentIsoDate, requiredProperties, takeover);

        UpshowLogger.debug(['spotlights'].concat(requiredProperties), `getActiveFullscreenSpotlights, had ${_.size(SpotlightService.schedules)}, for datetime: ${currentIsoDate}, filtered to ${_.size(activeFullscreenSpotlights)}`);

        return activeFullscreenSpotlights;
    },
    filterSchedules: function filterSchedules (schedules, currentIsoDate, requiredProperties, takeover = false) {

        // Guard against uninitialized or missing list of spotlights
        if (!schedules) {
            return [];
        }

        if (requiredProperties) {
            schedules = _.filter(schedules, { 'properties': requiredProperties });
        }

        // No connection? no streaming for you!
        let connected = UP.isConnected();
        const default_tz = SettingsService.getTimezone();

        let filtered = schedules.filter(function onlyActive (schedule) {
            if (!connected && schedule.requiresConnection) {
                return false;
            }

            if (currentIsoDate < '2015-01-01T01:01:01.000Z') {
                UpshowLogger.error('spotlight', ' [error] scheduling: system date ' + currentIsoDate + '  looks wrong, will ignore weekly scheduling.');
                return true;
            }

            if (takeover && schedule.behavior !== 'takeover') {
                return false;
            }

            return scheduleisActive(currentIsoDate, schedule, default_tz);
        });

        // Randomize ( if required ) and return that array
        return randomStart(_.uniqBy(filtered, 'id'));
    },
    getFirstValidSpotlightID: async function getFirstValidSpotlightID (spotlightId, state) {
        if (!SettingsService.hasFeature('dr_time_based')) return spotlightId;

        const currentIsoDate = UP.getCurrentISODate();
        const ruleListValidator = DisplayRulesService.getRulesValidator(currentIsoDate);

        const spotlight = await SpotlightService.getSpotlight(spotlightId);
        if (ruleListValidator(spotlight.time_based_display_rules)) return spotlightId;

        for (let step = 0; step < state.metadata.id.length; step++) {
            const nextSptId = ScriptService.getCurrentGranularityId(state);
            const spotlight = await SpotlightService.getSpotlight(nextSptId);

            if (ruleListValidator(spotlight.time_based_display_rules)) return nextSptId;
        }

        return null;
    },
    getSpotlightData: async function getSpotlightData (spotlightId) {
        if (!spotlightId) throw new Error('Mising spotlight id');

        const response = await UP.getSpotlightData(spotlightId);
        const { server_error, error } = response;
        if (server_error) {
            UpshowLogger.error(['SpotlightService', 'getSpotlight'], server_error);
            throw new Error(server_error);
        } else if (error) {
            throw new Error(error);
        } else if (response.spotlight) {
            return format_spotlight(response.spotlight);
        }
    }
};

export default SpotlightService;

