import Logger from '../Logger';
import { getApplicationInstance } from '../Upshow';
import { StatePreconditionError } from '../states/Errors';
import SpotlightService from '../services/SpotlightService';
import { spotlightValidator } from '@upshow/spotlight-player';

export const QUADRANT_KEYS = ['quadrant_1', 'quadrant_2', 'quadrant_3', 'quadrant_4'];

export const LAYOUT_KEYS_MAP = {
    'FULLSCREEN': 'fullscreen',
    '4_SCREEN': '4_screen',
};

export const getApplicationFromId = async (application_id) => {
    try {
        const application = await getApplicationInstance(application_id);
        const { application_instance, error } = application;

        if (error) {
            Logger.error(`multiple-layout - Application Instance Error - [Application ID: ${application_id}]`, error);
            return null;
        }

        if (!application_instance || !application_instance.application.url || !application_instance.app_key) {
            throw new StatePreconditionError('url and app key are required');
        }

        return application_instance;
    } catch (error) {
        Logger.error(`multiple-layout - Invalid application - [Application ID: ${application_id}]`, error);
        return null;
    }
};

export const getSpotlightFromId = async (spotlight_id) => {
    try {
        let spotlight = await SpotlightService.getSpotlight(spotlight_id);

        const check = spotlightValidator(spotlight);
        if (check !== true) {
            Logger.error(`multiple-layout - Spotlight Validator Error - [Spotlight ID: ${spotlight_id}]`, check?.error);
            return null;
        }
        return spotlight;

    } catch (error) {
        Logger.error(`multiple-layout - Invalid spotlight - [Spotlight ID: ${spotlight_id}]`, error);
        return null;
    }
};