import axios from 'axios';
import bluebird from 'bluebird';
import { v4 as uuidv4 } from 'uuid';
import md5 from 'blueimp-md5';

import { inc, logMetrics } from './libs/instrumentation';
import fit_ui_container from './libs/fit_ui_container';

import SettingsService from './services/SettingsService';
import ScreenService from './services/ScreenService';
import StateService from './services/StateService';

import { cleanupStoredKeys } from './Activation';
import UpshowLogger from './Logger';
import { addDeviceOrgToURL } from './libs/helpers';

const STATIC_EXTENSION_ID = process.env.REACT_APP_KIOSK_EXTENSION_ID;

//Shamelessly copied from http://stackoverflow.com/questions/12049620/how-to-get-get-variables-value-in-javascript
export let $_GET = {};
if (document.location.toString().indexOf('?') !== -1) {
    const query = document.location
        .toString()
        // get the query string
        .replace(/^.*?\?/, '')
        // and remove any existing hash string (thanks, @vrijdenker)
        .replace(/#.*$/, '')
        .split('&');

    for (let i = 0, l = query.length; i < l; i++) {
        let aux = query[i].split('=');
        $_GET[aux[0]] = decodeURIComponent(aux[1]);
    }
}

let connected = true;
const userAgent = navigator.userAgent || '';
export const isBrightSign = /^BrightSign/.test(userAgent);
export const isChromeOs = /\bCrOS\b/.test(userAgent);
let localUrlsCache = new Map();

// Set Version
const pjson = require('../package.json');
let uiVersion = pjson.version;
// Override version
if (process.env.REACT_APP_APP_VERSION) {
    uiVersion = process.env.REACT_APP_APP_VERSION;
}
// We are in development mode, so we are appending -dev to the end of the version
if (process.env.NODE_ENV === 'development') {
    uiVersion += '-dev';
}

const start_time = (new Date()).getTime();

export function getUptime () {
    return (new Date()).getTime() - start_time;
}

export const ERROR_CACHE_NOT_FOUND = 'Cache Not Found';
export const ERROR_DEACTIVATED = 'Api Key Deactivated';

let appVersion = '';
if (typeof $_GET['app_version'] !== 'undefined') {
    //override the model
    appVersion = $_GET['app_version'];
}

let deviceId = $_GET['deviceId']; // Chrome APP
let serialNumber = $_GET['serialNumber']; // Skykit

// If this variable is set to true we are going to use the static backend and disable app events polling
window.st = ($_GET['st'] === '1');

let model = 'WebView';
let make = 'WebView';

if (isChromeOs) {
    model = 'ChromeOS';
    //Asus means "Chromebit" for PlutoTV
    make = 'Asus';
}

let extensionAvailable = false;

export function checkExtension () {
    if (chrome.runtime) {
        chrome.runtime.sendMessage(STATIC_EXTENSION_ID, {
            methodName: 'getDeviceId',
        }, function (arg) {
            if (arg.error) {
                UpshowLogger.error('UIExtension', arg.error);
            } else {
                extensionAvailable = true; // We only consider the extension to be available if there are no errors
                deviceId = arg.deviceId;
                if (arg.api_token) window.localStorage.setItem('access-token', arg.api_token);
                if (arg.token_error) UpshowLogger.error('UIExtension', arg.token_error);
                UpshowLogger.log('UIExtension', `Device info received ${JSON.stringify(arg)}`);
            }
        });
    } else {
        UpshowLogger.error('UIExtension', 'chrome.runtime is unavailable');
    }
}

if (isChromeOs && !appVersion) {
    model = 'ChromeOS PWA';
    checkExtension();
}

if (isBrightSign) {
    model = 'BrightSign';
    make = 'BrightSign';

    const parts = userAgent.split('/');
    if (parts.length >= 3) {
        deviceId = parts[1];
    }
}

if ($_GET['model'] === 'Desktop') {
    model = 'Desktop';
    make = 'Chrome';
}

let modelProvided = false;
export let sessionId = uuidv4();

let fireOsModels = [
    'AFTRS',    //tv
    'AFTT',     // gen2 stick
    'AFTS',     // gen2 fire tv
    'AFTM',     // gen1 stick
    'AFTB',     // gen1 fire tv
];

for (let fireOsModel of fireOsModels) {
    if (userAgent.indexOf(fireOsModel) > -1) {
        model = fireOsModel;
        make = 'Amazon';
    }
}

//Must be before Skykit because skykit reports android too and otherwise it would be
//incorrectly reported
if (userAgent.includes('Android')) {
    model = 'AndroidNew';
    make = 'Google';
}

if (userAgent.includes('Skykit')) {
    if (window.UPshowAndroidApp) {
        model = 'AndroidSkykit';
    } else {
        model = 'Skykit';
    }
    make = 'Skykit';
}

console.info({ appVersion, uiVersion, model, make });

document.documentElement.className += ' ' + model;

let axiosInit = { method: 'GET', headers: {} };
let apiToken = '';

function setupAjax (token) {
    const headers = {
        'x-access-token': token,
        'x-app-version': appVersion
    };

    if (window.st) headers['x-static'] = true;
    axiosInit = { method: 'GET', headers };
}

export function setConnected (connectedStatus) {
    connected = connectedStatus;
    if (!connected) {
        document.getElementById('network-down-indicator').className = 'no-network';
    } else {
        document.getElementById('network-down-indicator').className = '';
    }
}

export function isConnected () {
    return !!connected;
}

function getCachedResponse (url) {
    const cachedToken = window.localStorage.getItem('cachedtoken-' + url);
    if (cachedToken && apiToken !== cachedToken) {
        return Promise.reject(ERROR_CACHE_NOT_FOUND);
    }
    const cachedResponse = window.localStorage.getItem('cachedresponse-' + url);
    if (cachedResponse) {
        return Promise.resolve(cachedResponse);
    } else {
        return Promise.reject(ERROR_CACHE_NOT_FOUND);
    }
}

function cacheResponse (url, response) {
    return Promise.resolve().then(() => {
        window.localStorage.setItem('cachedtoken-' + url, apiToken);
        window.localStorage.setItem('cachedresponse-' + url, response);
        return response;
    });
}

export function clearSpotlightCache () {
    const keysToDelete = [];
    for (let i = 0; i < window.localStorage.length; i++) {
        const key = window.localStorage.key(i);
        if (key.indexOf('/spotlight/') !== -1) {
            keysToDelete.push(key);
        }
    }

    [...new Set(keysToDelete)].forEach(k => window.localStorage.removeItem(k));
}

function bailOrFail (url, err) {
    UpshowLogger.error(['upshow', 'bailOrFail'], err.message, { url });

    if (err && err.response && err.response.status === 430) {
        const hasWrapper = isChromeOs || make === 'Amazon';

        cleanupStoredKeys();
        if (window.UPshowAndroidApp && window.UPshowAndroidApp.handleDeactivated) {
            UPshowAndroidApp.handleDeactivated(apiToken);
        } else if (!hasWrapper) {
            window.location.reload();
        } else {
            detachMessageListener();
        }
        throw ERROR_DEACTIVATED;
    } else {
        if ([431, 432].includes(err.response?.status)) {
            StateService.stop();
        }
        throw err;
    }
}

function cachingAxiosData (url, axiosInit, cacheIt = true, bailOnError = true) {
    const errorHandler = bailOnError ?
        err => bailOrFail(url, err) :
        err => {
            UpshowLogger.error(['upshow', 'cachingAxiosData', 'axios', 'response'], err);
            throw err;
        };
    return axios(url, axiosInit)
        .then(response => {
            const { data } = response;

            if (cacheIt) {
                try {
                    cacheResponse(url, JSON.stringify(data)).catch(err => {
                        UpshowLogger.error(['upshow', 'cachingAxiosData', 'axios'], `AXIOS FAILED - error saving ${url} on localStorage ${err}`);
                    });
                } catch (cachingErr) {
                    UpshowLogger.error(['upshow', 'cachingAxiosData', 'axios'], `AXIOS FAILED - error trying to save ${url} on localStorage ${cachingErr}`);
                }
            }
            return data;
        })
        .catch(errorHandler);
}

//Libraries
const getBaseURL = () => {
    const bypassVarnish = SettingsService.hasTrueUiSetting('bypassVarnish');

    return bypassVarnish ? process.env.REACT_APP_REST_URL : process.env.REACT_APP_REST_VARNISH_API_URL;
};

const apiUrls = {
    application_instance: process.env.REACT_APP_REST_URL + '/v4/media/application_instance/', // {application_instance, error, server_error}
    jukin_v2: process.env.REACT_APP_REST_URL + '/v4/media/jukin_v2/', // {jukin_channel_content, error, server_error}
    plutotv: process.env.REACT_APP_REST_URL + '/v4/media/plutotv/', // {pluto_channel, error, server_error}
    upshownow_v2: process.env.REACT_APP_REST_URL + '/v4/media/upshownow_v2/', // {upshownow_channel, error, server_error}
    spotlight: process.env.REACT_APP_REST_URL + '/v4/media/spotlight/', // //{spotlight, error, server_error},
    get state () { return getBaseURL() + '/v6/state'; }
};

export function getState () {
    return cachingAxiosData(apiUrls['state'], axiosInit, false);
}

export function getUpshowNowChannel (channelId) {
    return cachingAxiosData(apiUrls['upshownow_v2'] + channelId, axiosInit, false);
}

export function getJukinChannelData (channelId) {
    return cachingAxiosData(apiUrls['jukin_v2'] + channelId + '?limit=150', axiosInit, false);
}

export function getApplicationInstance (id) {
    return cachingAxiosData(apiUrls['application_instance'] + id, axiosInit, false);
}

export function getPlutoChannel (channelId) {
    return cachingAxiosData(apiUrls['plutotv'] + channelId, axiosInit, false);
}

export function getSpotlightData (spotlightId) {
    return getCachedSpotlightData(spotlightId);
}

export async function getCachedSpotlightData (spotlightId) {
    const result = await getCachedResponse(apiUrls['spotlight'] + spotlightId)
        .then((r) => {
            inc('spt-cache-hit');
            return JSON.parse(r);
        })
        .catch(() => {
            inc('spt-cache-miss');
            return cachingAxiosData(apiUrls['spotlight'] + spotlightId, axiosInit, true);
        });

    if (UpshowLogger.isDebugEnabled()) logMetrics();
    return result;
}

export function getDeviceInfo () {
    if (typeof $_GET['model'] !== 'undefined') {
        //override the model
        model = $_GET['model'];
        modelProvided = true;
    }

    const deviceInfo = {
        version: appVersion, uiVersion, connected,
        model, modelProvided, userAgent,
        sessionId, make, extensionAvailable,
        DeviceUptime: getUptime(),
        isVertical: ScreenService.isVertical,
    };

    if (typeof deviceId !== 'undefined') {
        deviceInfo.serial = deviceId;
    } else if (serialNumber) {
        deviceInfo.serial = serialNumber;
    } else {
        const serialId = localStorage.getItem('serial-id');
        if (serialId) {
            deviceInfo.serial = serialId;
        } else {
            deviceInfo.serial = 'X.' + uuidv4();
        }
    }

    localStorage.setItem('serial-id', deviceInfo.serial);

    return deviceInfo;
}

export function setToken (token) {
    apiToken = token;
    setupAjax(apiToken);
}

export function getToken () {
    return apiToken;
}

export function getLocationQueryParam (name) {
    return $_GET[name];
}

export async function teslaCacheY (given_url) {
    if (window.sw_enabled || SettingsService.hasTrueUiSetting('disable_tesla_cache')) return addDeviceOrgToURL(given_url);

    let attempt = 0;
    while (attempt++ < 10) {
        UpshowLogger.debug(['upshow', 'teslaCacheY'], `teslaCacheY attempt ${attempt - 1} for url ${given_url}`);

        const teslaUrl = teslaCacheX(given_url, (err) => { throw new Error(err); });

        if (teslaUrl !== given_url) {
            return teslaUrl;
        }

        await bluebird.delay(5000);
    }

    throw new Error();
}

let lastTimeIYelledToTheCloud = 0;

export function teslaCacheX (given_url, errorCallback) {
    return teslaCacheXI(given_url, errorCallback)?.url ?? addDeviceOrgToURL(given_url);
}

export function teslaCacheXI (given_url, errorCallback) {
    const onError = (err) => {
        if (typeof errorCallback === 'function') {
            errorCallback(err);
        }
    };

    // This is a general replacement from static to CDN
    let url = addDeviceOrgToURL(given_url
        .replace('https://s3.amazonaws.com/static.upshow.tv/', 'https://cdn.upshow.tv/')
        .replace('https://s3.amazonaws.com/spotlights.upshow.tv/', 'https://spotlightscdn.upshow.tv/'));

    const url_info = { url, given_url, from_cache: false };

    if (SettingsService.hasTrueUiSetting('disable_tesla_cache')) return url_info;

    const bannedHostnames = [
        'localhost',
        '127.0.0.1'
    ];

    if (url && bannedHostnames.includes(new URL(url).hostname)) {
        throw new Error('Will not cache localhost');
    }

    // No cache on the following models (we already know that)
    if (model === 'WebView' || model === 'BrightSign' || model === 'Skykit' || model === 'AndroidSkykit' || model === 'ChromeOS PWA') {
        onError(`No cache for ${model}`);
    } else {
        if (model === 'ChromeOS' || model === 'Desktop') {
            const urlMd5 = md5(url);
            const localUrl = localUrlsCache.get(urlMd5);
            if (localUrl) {
                UpshowLogger.info(['upshow', 'teslaCacheX', 'localUrl'], `${url} - ${localUrl}`);
                url_info.url = localUrl;
                url_info.from_cache = true;
            } else {
                if (!!window.proxyBridge && !!window.proxyOrigin) {
                    let msg = { action: 'teslaCache', url: url };
                    window.proxyBridge.postMessage(msg, window.proxyOrigin);
                } else {
                    const error = new Error(`tesla error`);
                    error.payload = url;
                    if (getUptime() < 30000) { //allowing some time for the app to turn tesla on
                        error.message = `There is not connexion to Chrome App from teslaCache yet`;
                        UpshowLogger.info(['upshow', 'teslaCacheX', 'localUrl'], error);
                    } else {
                        if (lastTimeIYelledToTheCloud < new Date(Date.now() - 60000)) {
                            lastTimeIYelledToTheCloud = new Date();
                            error.message = `Tesla Cache is OFF`;
                            UpshowLogger.error(['upshow', 'teslaCacheX', 'localUrl'], error);
                        }
                        onError('Tesla Cache is OFF on this Chromebit');
                    }
                }
            }
        } else {
            if (typeof window.Android === 'undefined') {
                //not android, no tesla
                onError('No cache backend found on Android');
            } else {
                try {
                    const response = JSON.parse(window.Android.teslaCache3(url));
                    if (response.error.length > 0) {
                        if (response.error === 'Cache enabled but url not in cache') {
                            UpshowLogger.info(['cache', 'teslaCacheX'], response.error);
                        } else {
                            UpshowLogger.error(['cache', 'teslaCacheX'], response.error);
                        }

                        return url_info;
                    } else {
                        UpshowLogger.debug(['cache', 'teslaCacheX'], ' requested: ' + url + ' GOT: ' + response.url);

                        url_info.url = response.url;
                        url_info.from_cache = true;
                    }
                } catch (e) {
                    UpshowLogger.error(['cache', 'teslaCacheX'], 'Error loading from teslaCacheX: ' + url);
                    UpshowLogger.error(e);
                    onError('Error loading from teslaCacheX');
                }
            }
        }
    }
    return url_info;
}

export function clearTeslaCache () {
    localUrlsCache.clear();
    if (!!window.proxyBridge && !!window.proxyOrigin) {
        let msg = { action: 'removecache' };
        window.proxyBridge.postMessage(msg, window.proxyOrigin);
    }
}

export function spaceQuotaTeslaCache () {
    if (!!window.proxyBridge && !!window.proxyOrigin) {
        let msg = { action: 'spacequota' };
        window.proxyBridge.postMessage(msg, window.proxyOrigin);
    } else {
        UpshowLogger.warn(['upshow', 'spaceQuotaTeslaCache'], 'No proxy bridge for spaceQuotaTeslaCache');
    }
}

export function getTeslaCache () {
    if (!!window.proxyBridge && !!window.proxyOrigin) {
        let msg = { action: 'getTeslaCache' };
        window.proxyBridge.postMessage(msg, window.proxyOrigin);
    } else {
        UpshowLogger.warn(['upshow', 'getTeslaCache'], 'No proxy bridge for getTeslaCache');
    }
}

export function windowMessageListener (event) {
    if (event.data === 'ping') {
        event.source.postMessage('pong', event.origin);
    } else if ((event.data.action && event.data.action === 'pingTeslaCache') || event.data === 'pingTeslaCache') {

        if (!window.proxyBridge || !window.proxyOrigin) {
            window.proxyBridge = event.source;
            window.proxyOrigin = event.origin;
        }

        if (localUrlsCache.size === 0) {
            let msg = { action: 'getTeslaCache' };
            window.proxyBridge.postMessage(msg, window.proxyOrigin);
        }

    } else if (event.data.action && event.data.action === 'teslaCacheResponse') {
        const { teslaCache } = event.data;
        if (Array.isArray(teslaCache)) {
            UpshowLogger.info(['upshow', 'teslacache'], JSON.stringify(teslaCache));
            teslaCache.forEach(cacheElement => {
                const { localUrl, urlMd5 } = cacheElement;
                localUrlsCache.set(urlMd5, localUrl);
            });
        } else {
            const { localUrl, urlMd5 } = event.data;
            localUrlsCache.set(urlMd5, localUrl);
        }

    } else if (event.data.action && event.data.action === 'teslaCacheRemoveKey') {
        const { key } = event.data;
        localUrlsCache.delete(key);
    }
}

export function attachMessageListener () {
    //Respond to keep alive only after initialization success
    window.addEventListener('message', windowMessageListener, false);
}

export function detachMessageListener () {
    //Respond to keep alive only after initialization success
    window.removeEventListener('message', windowMessageListener);
    localUrlsCache.clear();
}

let timestampOffset = 0;

export function setTimestampOffset (offset) {
    timestampOffset = offset;
}

export function setCurrentISODate (isoString) {
    UpshowLogger.log(['upshow', 'settings', 'timestamp'], 'Set current date to ' + isoString);

    const newDate = new Date(isoString);
    const offset = newDate.getTime() - Date.now();
    setTimestampOffset(offset);

}

export function hasTimestampOffset () {
    return timestampOffset !== 0;
}

export function canReportTts () {
    // Disable TTS reporting when timestamp is forced
    return !hasTimestampOffset();
}

export function getCurrentTimestamp () {
    return Date.now() + timestampOffset;
}

export function getCurrentDate () {
    return new Date(getCurrentTimestamp());
}

export function getCurrentISODate () {
    return getCurrentDate().toISOString();
}

export function setOverscan (overscan) {
    if (overscan !== !!window.overscan) {
        window.overscan = overscan;
        fit_ui_container();
    }
}

export function setFitToScreen (fitToScreen) {
    if (fitToScreen !== !!window.fit_to_screen) {
        window.fit_to_screen = fitToScreen;
        fit_ui_container();
    }
}

export function setBlackBackground (blackBackground) {
    const hasBlackBackground = document.body.classList.contains('blackBackground');
    if (blackBackground && !hasBlackBackground) {
        document.body.classList.add('blackBackground');
    } else if (!blackBackground && hasBlackBackground) {
        document.body.classList.remove('blackBackground');
    }
}

export function next () {
    return StateService.advanceState();
}